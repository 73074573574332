import { api } from './ApiSettings'
import http from './http'

import type {
    IBackofficeCompany,
    ICompany,
    ICompanyCreate,
    ICompanyFacilityLists,
    ICompanyFeature,
    ICompanyGroup,
    ICompanyReduced,
    ICompanyUpdate,
    ICompanyUserNotificationSettings,
    ISimpleCompany,
} from '@/modules/CORE/company/interfaces/ICompany'
import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { ILocality } from '@/modules/CORE/company/interfaces/ILocality'
import type { IFacilityGroup, IRegion } from '@/modules/CORE/company/interfaces/IRegion'
import type {
    ICompanyFacilityRelation,
    IFacilityAPIStatusType,
    IFacilityLessor,
    IFacilityManufacturer,
    IFacilityOwned,
    IFacilitySimpleV4,
    IFacilitySimpleV5,
    IFacilityTimeseries,
} from '@/modules/CORE/facility/interfaces/IFacility'
import type { IWorkorderCountStatus } from '@/modules/MAINTENANCE/workorders/interfaces/IWorkorder'
import type { AxiosResponse } from 'axios'
import type { IMCDropdownSingleOption } from 'moen-components'

import { toTitleCase } from '@/utils'

export default {
    createCompany: async (companyPayload: ICompanyCreate): Promise<ICompany | undefined> => {
        const url = api('v1/companies/')
        let response: AxiosResponse | undefined
        let company: ICompany | undefined
        try {
            response = await http.post(url, companyPayload)
            if (response?.status === 201) {
                company = await response.data
            }
        } catch (err) {
            console.error(err)
        }

        return company
    },
    deleteCompany: async (companyId: UUIDv4): Promise<boolean> => {
        const url = api(`v1/companies/${companyId}/`)
        try {
            const response = await http.delete(url)
            if (response && response.status === 204) {
                return true
            } else {
                return false
            }
        } catch (err) {
            console.error(err)
            return false
        }
    },
    fetchCompanyByCompanyId: async (companyId: UUIDv4): Promise<ICompany | undefined> => {
        const url = api(`v1/companies/${companyId}/`) // adjust the serializer to define what data should be returned
        let response: Response | undefined
        let company: ICompany | undefined
        try {
            response = await http.get(url)

            if (response.ok) {
                company = await response.json()
            }
        } catch (err) {
            console.error(err)
        }
        return company
    },
    fetchCompanyByCompanyIdV4: async (companyId: UUIDv4): Promise<ICompanyReduced | undefined> => {
        const url = api(`v4/companies/${companyId}/`) // adjust the serializer to define what data should be returned
        let response: Response | undefined
        let company: ICompanyReduced | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                company = await response.json()
            }
        } catch (err) {
            console.error(err)
        }
        return company
    },

    fetchCompanyGroupsByCompanyId: async (companyId: UUIDv4): Promise<ICompanyGroup[] | undefined> => {
        try {
            const url = api(`v1/companies/${companyId}/groups/`)
            const response = await http.get(url)

            if (response.ok) {
                const data = await response.json()
                return data as ICompanyGroup[]
            } else {
                throw new Error(`Request failed with status ${response.status}`)
            }
        } catch (error) {
            console.error('Error fetching company groups:', error)
            throw error
        }
    },

    setGroupForUser: async (group: ICompanyGroup, user_id: UUIDv4, company: ICompany | undefined = undefined) => {
        // todo: deprecate this
        const url = api(`v1/users/${user_id}/set_group/`)
        const errorMessage = 'Failed to set group for user'
        const body = company && company.id ? { group, company_id: company.id } : { group: group.name }
        let response = null
        try {
            response = await http.post(url, JSON.stringify(body))
        } catch (e: any) {
            console.error(errorMessage, e)
            return { status: e.response.status }
        }

        return response
    },

    fetchSimpleCompaniesByCurrentUser: async (): Promise<ISimpleCompany[] | undefined> => {
        //* User is decided in the http-header
        const url = api('v4/companies/')
        let response: Response | undefined
        let companies: ISimpleCompany[] | undefined

        try {
            response = await http.get(url)
            companies = await response.json()
        } catch (err) {
            console.error(err)
        }

        return companies
    },

    fetchBackofficeCompanies: async (sortBy: string = 'created', sortDir: string = 'desc'): Promise<IBackofficeCompany[]> => {
        const url = api(`v4/backoffice/companies/?sort_by=${sortBy}&sort_dir=${sortDir}`)
        let response: Response | undefined
        let companies: IBackofficeCompany[] = []
        try {
            response = await http.get(url)
            if (response.ok) {
                companies = await response.json()
            }
        } catch (err) {
            console.error(err)
        }

        return companies
    },

    fetchFeatureByCompanyId: async (companyId: UUIDv4): Promise<ICompanyFeature[] | undefined> => {
        const url = api(`v1/company/${companyId}/features/`)
        let response: Response | undefined
        let features: ICompanyFeature[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                features = await response.json()
            }
        } catch (err) {
            console.error(err)
        }
        return features
    },

    fetchFacilitiesByCompanyId: async (companyId: UUIDv4): Promise<ICompanyFacilityLists | undefined> => {
        const url = api(`v3/companies/${companyId}/facilities/`)
        let response: Response | undefined
        let facilities: ICompanyFacilityLists | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                facilities = await response.json()
            }
        } catch (err) {
            console.error(err)
        }
        return facilities
    },
    fetchFacilitiesByCompanyIdV4: async (companyId: UUIDv4): Promise<IFacilitySimpleV4[]> => {
        const url = api(`v4/companies/${companyId}/facilities/`)
        let response: Response | undefined
        let facilities: IFacilitySimpleV4[] = []
        try {
            response = await http.get(url)
            if (response.ok) {
                facilities = await response.json()
            }
        } catch (err) {
            console.error(err)
        }
        return facilities
    },
    fetchFacilitiesByCompanyIdV5: async (companyId: UUIDv4): Promise<IFacilitySimpleV5[]> => {
        const url = api(`v5/companies/${companyId}/facilities/`)
        let response: Response | undefined
        let facilities: IFacilitySimpleV5[] = []
        try {
            response = await http.get(url)
            if (response.ok) {
                facilities = await response.json()
            }
        } catch (err) {
            console.error(err)
        }
        return facilities
    },
    fetchFacilityStatusByType: async (
        companyId: UUIDv4,
        type: IFacilityAPIStatusType,
    ): Promise<IWorkorderCountStatus[] | IWorkorderCountStatus[] | undefined> => {
        const version = type === 'workorders' ? 'v2' : 'v1'
        const url = api(`${version}/companies/${companyId}/facilities/status/${type}/`)
        const errorMessage = `[companies.js] Failed to fetch ${type} for Company (id=${companyId}).`
        let response: Response | undefined
        let statuses: IWorkorderCountStatus[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                statuses = await response.json()
            }
        } catch (e) {
            console.error(errorMessage, e)
        }
        return statuses
    },

    fetchFacilityUUIDsByCompanyId: async (companyId: UUIDv4): Promise<UUIDv4[]> => {
        const url = api(`v2/companies/${companyId}/facilities/`)
        const response = await http.get(url)
        if (response.ok) {
            return response.json() as Promise<UUIDv4[]>
        } else {
            return []
        }
    },

    listFacilitiesByCompanyIdAndRelation: async (
        companyId: UUIDv4,
        relation: ICompanyFacilityRelation,
    ): Promise<IFacilityLessor[] | IFacilityManufacturer[] | IFacilityOwned[] | undefined> => {
        const url = api(`v1/companies/${companyId}/${relation}/`)

        let response: Response | undefined
        let facilities: IFacilityLessor[] | IFacilityManufacturer[] | IFacilityOwned[] | undefined

        try {
            response = await http.get(url)

            if (response?.ok) {
                facilities = await response.json()
            }
        } catch (err) {
            console.warn(err)
        }

        return facilities
    },

    fetchFacilityGroups: async (companyId: UUIDv4): Promise<IFacilityGroup[]> => {
        const url = api(`v1/companyfacilities/${companyId}/`)
        let response: Response | undefined
        let facilityGroups: IFacilityGroup[] = []
        try {
            response = await http.get(url)

            if (response?.ok) {
                facilityGroups = await response.json()
            }
        } catch (err) {
            console.warn(err)
        }
        return facilityGroups
    },

    inviteUserToCompany: async (newUser: any, dryRun: boolean = false): Promise<Response> => {
        const url = api('v1/invitations/')
        return http.postWithFetch(url, { ...newUser, dry_run: dryRun })
    },

    invitationsInCompany: async (companyId: UUIDv4): Promise<[]> => {
        const url = api(`v1/invitations-in-company/${companyId}/`)
        const errorMessage = 'Failed to get invitations in company'
        let response = null
        let invitations = []
        try {
            response = await http.get(url)
            if (response.ok) {
                invitations = await response.json()
            }
        } catch (err) {
            console.error(errorMessage, err)
        }
        return invitations
    },

    createRegion: async (region: IRegion): Promise<IRegion> => {
        const url = api(`v1/companies/${region.company}/regions/`)
        const errorMessage = 'Failed to create region.'
        let response = null
        let responseBody = null
        try {
            response = await http.post(url, JSON.stringify(region))

            if (response.status === 200 || response.status === 201) {
                responseBody = response.data
            }
        } catch (e) {
            console.error(errorMessage, e)
        }

        return responseBody
    },

    deleteRegion: async (region: IRegion) => {
        const url = api(`v1/companies/${region.company}/regions/${region.id}/`)
        const errorMessage = 'Failed to delete region.'
        let response = null
        try {
            response = await http.delete(url)
        } catch (e) {
            console.error(errorMessage, e)
            return false
        }

        return true
    },

    editRegion: async (region: IRegion) => {
        const url = api(`v1/companies/${region.company}/regions/${region.id}/`)
        const errorMessage = 'Failed to edit region.'
        let response = null
        try {
            response = await http.put(url, JSON.stringify(region))
        } catch (e) {
            console.error(errorMessage, e)
            return false
        }

        return true
    },

    fetchRegion: async (companyId: UUIDv4, regionId: UUIDv4): Promise<IRegion | null> => {
        if (!companyId || !regionId) return null

        const url = api(`v1/companies/${companyId}/regions/${regionId}/`)
        const errorMessage = 'Failed to fetch region.'
        let response = null
        let responseBody = null
        try {
            response = await http.get(url)

            if (response.status === 200) {
                responseBody = await response.json()
            }
        } catch (e) {
            console.error(errorMessage, e)
        }

        return responseBody
    },

    createLocality: async (locality: ILocality, region: IRegion) => {
        const url = api(`v1/companies/${region.company}/regions/${region.id}/localities/`)
        const errorMessage = 'Failed to create locality.'
        let response = null
        let responseBody = null
        try {
            response = await http.post(url, JSON.stringify(locality))

            if (response.status === 200 || response.status === 201) {
                responseBody = await response.data
            }
        } catch (e) {
            console.error(errorMessage)
            return undefined
        }

        return responseBody
    },

    deleteLocality: async (locality: ILocality, region: IRegion) => {
        const url = api(`v1/companies/${region.company}/regions/${region.id}/localities/${locality.id}/`)
        const errorMessage = 'Failed to delete locality.'
        try {
            await http.delete(url)
        } catch (e) {
            console.error(errorMessage, e)
            return false
        }

        return true
    },

    editLocality: async (locality: ILocality, region: IRegion) => {
        const url = api(`v1/companies/${region.company}/regions/${region.id}/localities/${locality.id}/`)
        const errorMessage = 'Failed to edit locality.'
        try {
            await http.patch(url, JSON.stringify(locality))
        } catch (e) {
            console.error(errorMessage, e)
            return false
        }

        return true
    },

    // partial update with PATCH
    updateCompanyPatch: async (companyId: UUIDv4, companyPayload: ICompanyUpdate): Promise<void> => {
        const url = api(`v1/company/${companyId}/features/`)

        try {
            await http.patch(url, companyPayload)
        } catch (err) {
            console.error('Error in updateCompany:', err)
            throw new Error(`Failed to update company with ID ${companyId}: ${(err as Error).message}`)
        }
    },

    // full update with PUT
    updateCompanyPut: async (companyPayload: ICompany): Promise<ICompany | undefined> => {
        const url = api(`v1/companies/${companyPayload.id}/`)
        let response: AxiosResponse | undefined
        let company: ICompany | undefined
        try {
            response = await http.put(url, companyPayload)
            if (response?.status === 200) {
                company = await response.data
            }
        } catch (err) {
            console.error(err)
        }

        return company
    },
    companyFacilitiesWithAvailableTimeseriesData: async (companyId: UUIDv4): Promise<IFacilityTimeseries[]> => {
        const url = api(`v4/smart-support/company-facilities-with-available-timeseries-data/${companyId}/`)

        let response: Response | undefined
        let companyFacilities: IFacilityTimeseries[] = []

        try {
            response = await http.get(url)
            if (response.ok) {
                companyFacilities = await response.json()
            }
        } catch (err) {
            console.error(err)
        }
        return companyFacilities
    },
    fetchCompanyNameById: async (companyId: UUIDv4): Promise<ISimpleCompany> => {
        if (!companyId) {
            console.error('No company ID provided')
            return { id: '', name: '' }
        }

        const url = api(`v4/get-company-name/${companyId}/`)
        let response: Response | undefined
        let company: ISimpleCompany = { id: '', name: '' }
        try {
            response = await http.get(url)
            if (response.ok) {
                company = await response.json()
            }
        } catch (err) {
            console.error(err)
        }
        return company
    },
    fetchCompanyNotificationSettings: async (companyId: string): Promise<ICompanyUserNotificationSettings[] | undefined> => {
        const url = api(`company/${companyId}/user-company-notification-settings/`)
        let response: Response | undefined
        let settings: ICompanyUserNotificationSettings[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                settings = await response.json()
            }
        } catch (err) {
            console.error(err)
        }

        return settings
    },
    updateCompanyNotificationSettings: async (
        settingsPayload: ICompanyUserNotificationSettings,
    ): Promise<ICompanyUserNotificationSettings | undefined> => {
        const url = api(`company/${settingsPayload.company}/user-company-notification-settings/`)
        let response: AxiosResponse<ICompanyUserNotificationSettings> | undefined
        let updatedSettings: ICompanyUserNotificationSettings | undefined
        try {
            response = await http.patch(url, settingsPayload)
            if (response.status === 201) {
                updatedSettings = response.data
            }
        } catch (err) {
            console.error(err)
        }
        return updatedSettings
    },
    createCompanyNotificationSettings: async (
        settingsPayload: ICompanyUserNotificationSettings,
    ): Promise<ICompanyUserNotificationSettings | undefined> => {
        const url = api(`company/${settingsPayload.company}/user-company-notification-settings/`)
        let response: AxiosResponse<ICompanyUserNotificationSettings> | undefined
        let createdSettings: ICompanyUserNotificationSettings | undefined
        try {
            response = await http.post(url, settingsPayload)
            if (response.status === 201) {
                createdSettings = response.data
            }
        } catch (err) {
            console.error(err)
        }
        return createdSettings
    },
    fetchCompaniesWithYardEnabled: async (): Promise<IMCDropdownSingleOption[] | undefined> => {
        const url = api('v4/companies/with-yard/')

        let response: Response | undefined

        let companies: { id: string; name: string }[] | undefined

        // # TODO (JK): Rather make a system in backend that only includes "yard" companies and "demo" companies if superusers...
        const blacklistCompanyIds: string[] = ['0e50cf19-c5b1-40b1-bcac-0aa36671b79f', '0c448aa4-9825-4aba-a76c-befbbb07cd1b']

        try {
            response = await http.get(url)

            if (response.ok) {
                companies = (await response.json()) as { id: string; name: string }[]
            }
        } catch (err) {
            console.error('Error fetching companies with yard enabled:', err)

            return undefined
        }

        return companies

            ?.filter((company) => !blacklistCompanyIds.includes(company.id))

            .map((company) => ({
                id: toTitleCase(company.name),
                value: toTitleCase(company.name),
            }))
    },
}
