import axios from 'axios'
import {
    addMinutes,
    format,
    formatDistanceToNow,
    formatISO,
    parseISO,
    setHours,
    setMilliseconds,
    setMinutes,
    setSeconds,
    startOfWeek,
} from 'date-fns'
import enLocale from 'date-fns/locale/en-US'
import nbLocale from 'date-fns/locale/nb'
import debounce from 'lodash/debounce'

// import { Permission } from '@/modules/CORE/interfaces/IUser'

import { host } from './api/ApiSettings'

// import { IEquipment } from './modules/MAINTENANCE/equipment/interfaces/IEquipment'
//
// import type { CRUDPermissions } from './modules/CORE/interfaces/IPermissions'

import type {
    AllPermissions,
    GroupPermissions,
    IFacility,
    IFacilityOwned,
    IFacilitySimpleV4,
    IFacilityV4,
} from './modules/CORE/facility/interfaces/IFacility'
import type { GroupPermissionDescription, ICompanyUser, IGroup, IUser } from './modules/CORE/interfaces/IUser'
import type { IMaintenanceRule } from './modules/MAINTENANCE/checklist/interfaces/IChecklist'
import type { DocumentPermissions, IDocument, IDocumentBasic } from './modules/MAINTENANCE/documents/interfaces/IDocument'
import type { EquipmentPermissions, IEquipmentBasic } from './modules/MAINTENANCE/equipment/interfaces/IEquipment'
import type {
    CorrectiveWorkorderPermissions,
    IWorkorder,
    PreventiveWorkorderPermissions,
} from './modules/MAINTENANCE/workorders/interfaces/IWorkorder'
import type { IAGGridState } from './modules/SUPERUSER/interfaces/IAGGrid'
import type { IRemarkSignatureUser, RemarkPermissions } from './modules/YARD/remarks/interfaces/IRemarks'
import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import { FacilityPermissions } from '@/modules/CORE/facility/interfaces/IFacility' // '../facility/interfaces/IFacility'
import type { Router } from 'vue-router'

import { currentLocale } from '@/i18n'
import { i18n } from '@/i18n.js'
import type { ModelType } from './modules/CORE/interfaces/GroupAndPermissions'

export const getFirstDayOfWeek = (date: Date) => {
    const firstDay = startOfWeek(date, { weekStartsOn: 1 }) // Monday as the first day
    return setMilliseconds(setSeconds(setMinutes(setHours(firstDay, 0), 0), 0), 0)
}

/**
 * Asserts that a given condition is true.
 *
 * This function is used to ensure that a specific condition holds true.
 * If the condition is false, it throws an error with the provided message.
 * This is useful for enforcing invariants and validating assumptions in your code.
 *
 * @param condition - The condition to evaluate. If the condition is falsy, an error is thrown.
 * @param message - The message to include in the error if the condition is false.
 *
 * @throws {Error} If the condition is false, an error is thrown with the provided message.
 *
 * @example
 * // Example usage in a function
 * function exampleFunction(value: number) {
 *     assert(value > 0, "Value must be greater than 0");
 *     // Rest of the function logic
 * }
 *
 * // Example usage in a test
 * function testExample() {
 *     assert(true, "This should not throw"); // This will not throw an error
 *
 *     try {
 *         assert(false, "This should throw an error");
 *     } catch (e) {
 *         console.info(e.message); // Expected output: "This should throw an error"
 *     }
 * }
 */
export const assert: (condition: unknown, message?: string) => asserts condition = (condition, message = 'Assertion failed') => {
    if (!condition) throw new Error(message)
}

export const createAvatarLetters = (inputString: string) => {
    // Function to handle edge cases
    const handleEdgeCases = (str: string) => {
        switch (str) {
            case 'AquaGen':
                return 'AG'
            case 'Norcod AS':
                return 'NC'
            // Add more cases as needed
            default:
                return str
        }
    }

    // Check for edge cases
    const edgeCaseResult = handleEdgeCases(inputString)
    if (edgeCaseResult !== inputString) {
        return edgeCaseResult
    }

    // Split the input string into words
    const words = inputString.split(' ')

    // Initialize an empty string to store the avatar letters
    let avatarLetters = ''

    // Determine the number of words to consider
    const numWordsToConsider = Math.min(2, words.length)

    // Iterate through the words to consider
    for (let i = 0; i < numWordsToConsider; i++) {
        const word = words[i]
        // If the word is not empty, add its first letter to the avatar letters
        if (word && word.length > 0) {
            avatarLetters += word[0].toUpperCase()
        }
    }

    // if there is only one letter, add the second letter of the first word as well
    if (numWordsToConsider === 1 && words[0].length > 1) {
        avatarLetters += words[0][1].toUpperCase()
    }

    // Return the concatenated avatar letters
    return avatarLetters
}

export const cookieName = (type: string) => {
    let csrftoken
    let sessionid
    if (host.includes('backend.staging.m-link.no')) {
        csrftoken = 'csrftoken-staging-2.1'
        sessionid = 'sessionid-staging-2.1'
    } else if (host.includes('backend.dev.m-link.no')) {
        csrftoken = 'csrftoken-dev-2.1'
        sessionid = 'sessionid-dev-2.1'
    } else if (host.includes('backend.m-link.no')) {
        csrftoken = 'csrftoken-2.1'
        sessionid = 'sessionid-2.1'
    } else if (host.includes('localhost')) {
        csrftoken = 'csrftoken'
        sessionid = 'sessionid'
    } else {
        console.error('[utils.js] Cookie check failed: possibly error with host variable')
        return false
    }

    if (type === 'csrf') {
        return csrftoken
    } else if (type === 'session') {
        return sessionid
    } else {
        return false
    }
}

export const getRouteContext = (route: Router): 'company' | 'facility' | 'no-context' => {
    const params = route.currentRoute.value.params

    const companyId = params.companyId as UUIDv4 | string | undefined
    if (companyId && isUUIDv4(companyId)) return 'company'

    const facilityId = params.facilityId as UUIDv4 | string | undefined
    if (facilityId && isUUIDv4(facilityId)) return 'facility'

    return 'no-context'
}

export const isUUIDv4 = (possibleUUID: string | UUIDv4): boolean => {
    const isUUID: boolean = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(possibleUUID)
    return isUUID
}

export const sleep = async (ms: number = 1000): Promise<number> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

const dateObjectSerializer = (inputDate: any) => {
    const isDate = Boolean(inputDate instanceof Date)
    return isDate ? inputDate : parseISO(inputDate)
}

export const dateISOParserNorwegianDate = (inputDate: Date | string, withTime = false) => {
    if (!inputDate) return ''
    const dateObject = dateObjectSerializer(inputDate)
    if (withTime) {
        return format(dateObject, 'dd.MM.yyyy HH:mm')
    } else {
        return format(dateObject, 'dd.MM.yyyy')
    }
}

export const dateISOParserDateVariant = (inputDate: Date | string, currentLocale: 'no' | 'en' = 'en') => {
    if (!inputDate) return ''
    const dateObject = dateObjectSerializer(inputDate)
    let config = { locale: enLocale }
    if (currentLocale === 'no') {
        // @ts-expect-error-next-line
        config = { locale: nbLocale }
    }
    // @ts-expect-error-next-line
    return format(dateObject, 'd MMMM, yyyy', config)
}

export const dateISOParser = (inputDate: Date | string, customFormat = 'yyyy-MM-dd') => {
    if (!inputDate) return ''
    const dateObject = dateObjectSerializer(inputDate)
    return format(dateObject, customFormat)
}

export const todayISODate = () => {
    return dateISOParser(new Date())
}

export const dateTimeISOParser = (inputDate: Date | string, withSeconds: boolean = false) => {
    if (!inputDate) return ''
    const dateObject = dateObjectSerializer(inputDate)
    if (withSeconds) {
        return format(dateObject, 'yyyy-MM-dd HH:mm:ss')
    } else {
        return format(dateObject, 'yyyy-MM-dd HH:mm')
    }
}

export const dateTimeISOParserWithT = (inputDate: Date | string) => {
    if (!inputDate) return ''
    const dateObject = dateObjectSerializer(inputDate)
    return format(dateObject, "yyyy-MM-dd'T'HH:mm")
}

export const fromNow = (dateObject: Date, locale: string = 'en', suffix: boolean = true) => {
    let localeObject: any = enLocale
    // norwegian locale in getter: 'no', in date-fns: `date-fns/locale/nb`
    // english locale in getter: 'en', in date-fns: `date-fns/locale/en-US`
    if (locale === 'no' || locale === 'nb') {
        localeObject = nbLocale
    }
    if (suffix) {
        return formatDistanceToNow(dateObject, { locale: localeObject, addSuffix: true })
    } else {
        return formatDistanceToNow(dateObject, { locale: localeObject })
    }
}

export const truncate = (text: string, length: number, suffix: string): string => {
    if (text.length > length) {
        return text.substring(0, length) + suffix
    } else {
        return text
    }
}

export const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const generateUUIDv4 = () => {
    return window.crypto.randomUUID() as UUIDv4
}

export const getDateDaysBack = (currentTimestamp: string, daysBack: number): Date => {
    const currentDate = new Date(currentTimestamp)

    const previousDate = new Date(currentDate.getTime() - daysBack * 24 * 60 * 60 * 1000)

    return previousDate
}

export const slugifyText = (text: string): string => {
    return text.toLowerCase().replace(/ /g, '_').replace(/,/g, '').replace(/&/g, 'and').replace(/'/g, '')
}

export const round = (number: number | undefined, precision = 2): number | undefined => {
    if (number === undefined) return undefined
    try {
        const bulge = 10 ** precision || 100
        const roundedNumber = Math.round(number * bulge) / bulge
        const fixedNumberString = roundedNumber.toFixed(precision)
        return Number.parseFloat(fixedNumberString)
    } catch (err) {
        console.warn('could not round number', err)
        return undefined
    }
}

export const isValidEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/
    return regex.test(email)
}

export const isBannedWord = (str: string, list: string[]): boolean => {
    if (list.some((x) => str.toLowerCase().includes(x.toLowerCase()))) {
        console.log(str, 'is banned')
        return true
    } else {
        return false
    }
}

/**
 * Checks if a given string contains any of the characters from a specified array.
 *
 * @param {string} str - The string to be searched.
 * @param {string[]} characters - An array of characters to check against the string.
 * @returns {boolean} - Returns `true` if any character from the array is found in the string, otherwise returns `false`.
 *
 * @example
 * const myString = "Hello, world!";
 * const charactersToCheck = ['a', 'b', 'c'];
 */
export const containsAnyCharacters = (str: string, characters: string[]): boolean => {
    return characters.some((character) => str.includes(character))
}

/**
 * Splits a string based on an array of separator strings.
 *
 * @param {string} str - The string to be split.
 * @param {string[]} separators - An array of strings to use as separators.
 * @returns {string[]} - An array of strings, split at each point where any of the separators occur in the original string.
 *
 * @example
 * const text = "Hello, world! Welcome to programming.";
 * const separators = [",", "!", "."];
 *  Output: ["Hello", " world", " Welcome to programming", ""]
 */
export const splitBySeparators = (str: string, separators: string[]): string[] => {
    const escapedSeparators = separators.map((s) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
    const regex = new RegExp(escapedSeparators.join('|'), 'g')
    return str.split(regex)
}

interface PasswordStrengthResponse {
    feedback: {
        suggestions: string[]
        warning: string
    }
    password_strength: number
}

// Cloning
export const deepClone = (something: any) => {
    return JSON.parse(JSON.stringify(something))
}

export const shallowClone = (something: any) => {
    return { ...something }
    // return Object.assign({}, something)
}

export const getPasswordStrength = async (password: string): Promise<PasswordStrengthResponse> => {
    if (password.length === 0) return { feedback: { suggestions: [], warning: '' }, password_strength: 0 }
    const url = 'https://europe-north1-mlink-prod.cloudfunctions.net/password-strenght-calculator'

    try {
        const response = await axios.post(url, { password })
        return response.data as PasswordStrengthResponse
    } catch (error) {
        console.error('Error fetching password strength:', error)
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(`Error: ${error.response.status}`)
        } else {
            throw error
        }
    }
}

export const prettifyUserDisplayname = (userObj: IUser | ICompanyUser | IRemarkSignatureUser | null | undefined): string => {
    if (!userObj) {
        return ''
    }

    if (!('first_name' in userObj) || !('last_name' in userObj)) {
        return ''
    }

    if (userObj.first_name.length > 0 && userObj.last_name.length > 0) {
        // both first name and last name exist
        return `${userObj.first_name} ${userObj.last_name}`
    }

    if (userObj.first_name.trim().length === 0 && userObj.last_name.trim().length === 0) {
        // if first_name and last_name have zero length or just a whitespace ' ', then return email
        return userObj.email || ''
    }

    if (userObj.first_name.trim().length > 0) {
        // if first_name exists, but not last_name, return first_name
        return userObj.first_name
    }

    if (userObj.last_name.trim().length > 0) {
        // if last_name exists, but not first_name, return last_name
        return userObj.last_name
    }

    return userObj.email
}

/**
 * Compares two objects to determine if they have identical keys and values.
 * @param obj1 The first object to compare.
 * @param obj2 The second object to compare.
 * @returns True if both objects have identical keys and values, otherwise false.
 */
export const identicalObjects = <T extends Record<string, any>>(obj1: T, obj2: T): boolean => {
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) return true
    return false
}

// Check for file types
export const isImage = (filename: string | undefined): boolean => {
    if (!filename) return false
    const imageExtensions = /\.(?:png|jpeg|jpg|svg|gif)$/i
    return imageExtensions.test(filename)
}

export const isPdf = (filename: string | undefined): boolean => {
    if (!filename) return false
    const imageExtensions = /\.pdf$/i
    return imageExtensions.test(filename)
}

// connect utils
export const connectValueSerializer = (payload: any, combinedId: any, decimals: number): number | undefined => {
    const signalObject: any = payload[combinedId]
    if (!signalObject) return undefined

    const VALUE = signalObject.v

    const RETURN_VALUE = round(VALUE, decimals)

    if (RETURN_VALUE === undefined) return undefined

    return RETURN_VALUE
}

export const connectUnitSerializer = (payload: any, combinedId: any): string => {
    const signalObject: any = payload[combinedId]
    if (!signalObject) return ''

    const UNIT = signalObject.unit

    switch (UNIT) {
        case 'Celcius':
            return '°C'
        case 'degree':
            return '°'
        case 'Voltage':
            return 'V'
        case 'Ampere':
            return 'A'
        case 'RPM':
            return 'rpm'

        default:
            return UNIT
    }
}

export const connectLabelSerializer = (payload: any, combinedId: any): string => {
    const signalObject: any = payload[combinedId]
    if (!signalObject) return '-'

    const LABEL = signalObject?.signal

    switch (LABEL) {
        case 'Cell Temperature Max':
            return 'Cell temp max'
        case 'Cell Temperature Min':
            return 'Cell temp min'
        case 'Cell Temperature Avg':
            return 'Cell temp avg'
        case 'Cell Voltage Max':
            return 'Cell voltage max'
        case 'Cell Voltage Min':
            return 'Cell voltage min'
        case 'Cell Voltage Avg':
            return 'Cell voltage avg'
        case 'Speed Relative':
            return 'Wind speed'
        case 'Direction Relative':
            return 'Wind direction'
        case 'Course Over Ground':
            return 'Course over ground'
        case 'Speed Over Ground':
            return 'Speed over ground'
        case 'Fuel used Total':
            return 'Fuel used tot.'
        case 'Battery Voltage Start':
            return 'Start battery'
        default:
            return LABEL
    }
}

export const connectTankNameSerializer = (payload: any, combinedId: any): string => {
    const signalObject: any = payload[combinedId]
    if (!signalObject) return '-'

    const EQUIP_MAIN = signalObject?.equip_main

    const LOCATION_LONGITUDINAL: string | null = signalObject?.location_longitudinal || null

    let label = EQUIP_MAIN

    switch (EQUIP_MAIN) {
        case 'Fuel Tank Day':
            label = 'Fuel Day'
            break
        case 'Fuel Tank Main':
            label = 'Fuel Main'
            break
    }

    if (LOCATION_LONGITUDINAL) {
        return `${label} (${LOCATION_LONGITUDINAL})`
    } else {
        return label
    }
}

// Checklist utils
export const makeRuleName = (rule: IMaintenanceRule): string => {
    // @ts-expect-error-next-line
    const t = i18n.global.t

    let ruleName = ''
    if ('date_rule' in rule && rule.date_rule !== null) {
        // dagsBasert
        if (rule.date_rule.frequency === 'daily') {
            ruleName += `
            ${t('checklist.rule_names.every')} ${rule.date_rule.every > 1 && currentLocale.value !== 'en' ? rule.date_rule.every : ''} ${
                currentLocale.value === 'en' && rule.date_rule.every > 1 ? getOrdinalSuffix(rule.date_rule.every) : ''
            }
             ${t('checklist.frequencies_every.daily')}`
        }
        if (rule.date_rule.frequency === 'weekly') {
            ruleName += `${getWeekdayNames(rule.date_rule.days)}`
        }
        if (rule.date_rule.frequency === 'monthly') {
            ruleName += `
            ${t('checklist.rule_names.every')} ${rule.date_rule.every > 1 && currentLocale.value !== 'en' ? rule.date_rule.every : ''} ${
                currentLocale.value === 'en' && rule.date_rule.every > 1 ? getOrdinalSuffix(rule.date_rule.every) : ''
            }
             ${t('checklist.frequencies_every.monthly')} `
        }
        if (rule.date_rule.frequency === 'yearly') {
            ruleName += `
            ${t('checklist.rule_names.every')} ${rule.date_rule.every > 1 && currentLocale.value !== 'en' ? rule.date_rule.every : ''} ${
                currentLocale.value === 'en' && rule.date_rule.every > 1 ? getOrdinalSuffix(rule.date_rule.every) : ''
            }
             ${t('checklist.frequencies_every.yearly')}`
        }
    }
    if ('unit_rule' in rule && rule.unit_rule !== null && 'date_rule' in rule && rule.date_rule !== null) {
        ruleName += '. '
    }
    if ('unit_rule' in rule && rule.unit_rule !== null) {
        const hoursText = t('checklist.hours')
        const intervalText = t('checklist.interval')
        const limitText = t('checklist.limit')
        const everyUnit = rule.unit_rule.every_unit
        const warnInAdvance = rule.unit_rule.warn_in_advance

        ruleName += `${hoursText}(${intervalText}: ${everyUnit}, ${limitText}: ${warnInAdvance})`
    }
    return ruleName
}
export const getOrdinalSuffix = (number: number): string => {
    if (typeof number !== 'number' || Number.isNaN(number)) {
        throw new TypeError('Input must be a valid number.')
    }

    // Handling special cases for 11, 12, and 13
    if (number % 100 >= 11 && number % 100 <= 13) {
        return `${number}th`
    }

    // Handling other cases
    switch (number % 10) {
        case 1:
            return `${number}st`
        case 2:
            return `${number}nd`
        case 3:
            return `${number}rd`
        default:
            return `${number}th`
    }
}
export const getWeekdayNames = (days: string): string => {
    const t = i18n.global.t
    let returnedDays = ''
    days.split(',').forEach((day: string, i) => {
        switch (day) {
            case 'mo':
                returnedDays += t('common.weekdays_short.monday')
                break
            case 'tu':
                returnedDays += t('common.weekdays_short.tuesday')
                break
            case 'we':
                returnedDays += t('common.weekdays_short.wednesday')
                break
            case 'th':
                returnedDays += t('common.weekdays_short.thursday')
                break
            case 'fr':
                returnedDays += t('common.weekdays_short.friday')
                break
            case 'sa':
                returnedDays += t('common.weekdays_short.saturday')
                break
            case 'su':
                returnedDays += t('common.weekdays_short.sunday')
                break
        }
        if (i !== days.split(',').length - 1) returnedDays += ', '
    })
    return returnedDays
}

export const validateLatitude = (latitude: any): boolean => {
    const latitudeStr = typeof latitude === 'string' ? latitude : String(latitude)
    const regex: RegExp = /^-?\d+(?:\.\d+)?$/
    return regex.test(latitudeStr.trim()) && Number.parseFloat(latitudeStr.trim()) >= -90 && Number.parseFloat(latitudeStr.trim()) <= 90
}

export const validateLongitude = (longitude: any): boolean => {
    const longitudeStr = typeof longitude === 'string' ? longitude : String(longitude)
    const regex: RegExp = /^-?\d+(?:\.\d+)?$/
    return (
        regex.test(longitudeStr.trim()) && Number.parseFloat(longitudeStr.trim()) >= -180 && Number.parseFloat(longitudeStr.trim()) <= 180
    )
}

// Define an interface for the data type
interface DataPair {
    0: number
    1: number
}

export const serializeHighchartsData = (data: DataPair[], decimals: number = 2): DataPair[] => {
    if (!data || data.length === 0) return []

    // Get the local time zone offset in minutes from the browser
    const timezoneOffsetMinutes = new Date().getTimezoneOffset()

    // Convert the time zone offset to milliseconds
    const timezoneOffsetMs = -timezoneOffsetMinutes * 60 * 1000

    // Convert and adjust timestamps
    return data.map((pair: DataPair) => {
        const originalTimestamp = pair[0]

        const value = round(pair[1], decimals) as number

        // Convert timestamp to milliseconds and apply the time zone offset
        const adjustedTimestamp = originalTimestamp * 1000 + timezoneOffsetMs

        return [adjustedTimestamp, value]
    })
}

interface BucketSizes {
    [key: string]: string
}

export const getBucketSizeByLookbackPeriod = (lookbackPeriod: string): string => {
    const bucketSizes: BucketSizes = {
        '0:1:0': '1 min',
        '0:6:0': '1 min',
        '0:12:0': '1 hour',
        '0:24:0': '1 hour',
        '7:0:0': '1 hour',
        '14:0:0': '1 hour',
        '30:0:0': '1 day',
        '90:0:0': '1 day',
        '180:0:0': '1 day',
        '365:0:0': '1 day',
    }

    // Default bucket size
    const defaultBucketSize = '1 hour'

    return bucketSizes[lookbackPeriod] || defaultBucketSize
}

export const validateTime = (time: string, timezoneOffset = 0) => {
    if (!time) return ''
    const formattedTime = `${time}:00`
    const offsetTime = addMinutes(new Date(formattedTime), timezoneOffset)
    return formatISO(offsetTime, { representation: 'complete' })
}

// ag grid utility
export const comparatorEpochVsDate = (filterDate: Date, cellValue: any) => {
    if (!cellValue) return 0

    const cellDate = new Date(cellValue)
    // Reset time part for both dates to ignore the time
    const filterDateNoTime = new Date(filterDate.getFullYear(), filterDate.getMonth(), filterDate.getDate())
    const cellDateNoTime = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate())

    if (cellDateNoTime.getTime() === filterDateNoTime.getTime()) {
        return 0 // dates are equal
    }
    return cellDateNoTime < filterDateNoTime ? -1 : 1 // compare the dates
}

// ag grid utility
export const formatNorwegianDate = (params: any) => {
    try {
        if (!params.value) return ''
        return dateISOParserNorwegianDate(new Date(params.value), false)
    } catch (err) {
        return params.value
    }
}

export const formatNorwegianDateTime = (params: any) => {
    try {
        return dateISOParserNorwegianDate(new Date(params.value), true)
    } catch (err) {
        return params.value
    }
}

// ag grid utility
export const keyCreatorEpoch = (params: any) => {
    return new Date(params.value).getTime()
}

// ag grid utility
export const loadAGGridState = (key: string): IAGGridState | undefined => {
    if (!key) {
        console.error('loadAGGridState: key is not provided')
        return
    }

    const LOCAL_STORAGE_VALUE = localStorage.getItem(key)
    if (LOCAL_STORAGE_VALUE) {
        return JSON.parse(LOCAL_STORAGE_VALUE)
    }

    return undefined
}

// Text formatting
export const toTitleCase = (str: string): string =>
    str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

// facility utils
export const calculateDesignDisplayName = (facility: any, t: any) => {
    if (!facility) return ''
    if (facility.nab_type && facility.design_model) {
        const NAB_TYPE = t(`facility.nab_type.${facility.nab_type}`)
        const DESIGN_MODEL = facility.design_model

        const material = facility.hull_material
        const HULL_MATERIAL = material === 'aluminium' ? 'Alu' : material === 'steel' ? 'Steel' : ''
        return `${NAB_TYPE} ${DESIGN_MODEL} ${HULL_MATERIAL}`
    } else {
        return ''
    }
}

export default {
    cookieName,
}

/**
 * @param permission - A permission enum value (e.g. FacilityPermissions.ViewFacility).
 * @param object - An object with a `permissions` array (like IFacility, IDocument, etc.).
 * @returns true if the permission is present, false otherwise.
 *
 * @example
 * const facility: IFacility = {
 *   permissions: [FacilityPermissions.ViewFacility, FacilityPermissions.EditFacility]
 * };
 *
 * const canView = hasObjectPerm(FacilityPermissions.ViewFacility, facility); // true
 */
export const hasObjectPerm = <
    O extends { permissions: P[] },
    P extends
        | GroupPermissions
        | CorrectiveWorkorderPermissions
        | PreventiveWorkorderPermissions
        | DocumentPermissions
        | EquipmentPermissions
        | FacilityPermissions,
>(
    permission: P,
    object: O & { permissions: P[] },
): boolean => {
    return Array.isArray(object.permissions) && object.permissions.includes(permission)
}

export const groupNameParser = (groupName: string): string => {
    return groupName
}

type groupType =
    | 'admin'
    | 'employee'
    | 'inspector'
    | 'projectLeader'
    | 'projectResource'
    | 'yardProjectLeader'
    | 'yardProjectResource'
    | 'unknown'
/**
 * Parses a group object to determine its type based on the group's name.
 *
 * The function performs the following steps:
 * 1. Extracts and validates the UUID from the beginning of the `name` field.
 * 2. Identifies the group type based on the suffix of the `name` field.
 * 3. Returns the corresponding `groupType` or 'unknown' if validation or mapping fails.
 *
 * @param {IGroup} group - The group object to be parsed.
 * @returns {groupType} - The parsed group type. Possible values:
 *    - 'admin': Facility admin group.
 *    - 'employee': Facility employee group.
 *    - 'inspector': Inspector group.
 *    - 'projectLeader': Project leader group.
 *    - 'projectResource': Project resource group.
 *    - 'yardProjectLeader': Yard project leader group.
 *    - 'yardProjectResource': Yard project resource group.
 *    - 'unknown': If the UUID is invalid or the group type cannot be determined.
 *
 * @example
 * const group: IGroup = {
 *   id: 777,
 *   name: "8ac28e9b-6a0d-40ed-9b10-190c6bb0a900_facility_admin",
 *   display_name: "Nabwork 2411 Hybrid facility admin",
 *   users: 3,
 *   permissions: ["add_group", "change_group"]
 * };
 *
 * console.log(groupTypeParser(group)); // Output: "admin"
 */
export const groupTypeParser = (group: IGroup): groupType => {
    const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    const [potentialUuid, ...groupTypeSegments] = group.name.split('_')

    // Join the remaining segments to reconstruct the group type if there are multiple parts
    const groupTypeSegment = groupTypeSegments.join('_')

    if (!uuidv4Regex.test(potentialUuid)) return 'unknown'

    const typeMap: Record<string, groupType> = {
        facility_admin: 'admin',
        facility_employee: 'employee',
        inspector: 'inspector',
        project_leader: 'projectLeader',
        project_resource: 'projectResource',
        yard_project_leader: 'yardProjectLeader',
        yard_project_resource: 'yardProjectResource',
    }

    return typeMap[groupTypeSegment] || 'unknown'
}

/**
 * Checks if the user is a facility admin.
 * If a facility ID is provided, the function checks if the user is an admin for that specific facility.
 * Else, it checks if the user is an admin for any facility.
 * @param user The user object.
 * @param facilityPermissions The facility permissions.
 * @param facilityId The facility ID to check for admin permissions.
 * @returns True if the user is a facility admin, else false.
 */
export const isFacilityAdmin = (facility: IFacility): boolean => {
    const adminPermission = FacilityPermissions.ViewFacilityAdminPage
    return hasObjectPerm(adminPermission, facility)
}

export const isCompanyAdmin = (user: IUser): boolean => {}

export const getRandomInt = (max: number = 1) => {
    return Math.floor(Math.random() * max)
}

/**
 * Parses a permission value and returns a shorter, human-readable version of the permission name.
 *
 * @param {GroupPermissions | CorrectiveWorkorderPermissions | PreventiveWorkorderPermissions | DocumentPermissions | EquipmentPermissions | FacilityPermissions} permission
 *  The permission value to parse. Supports enums such as `GroupPermissions`, `CorrectiveWorkorderPermissions`, etc.
 *
 * @returns {string}
 *  A shorter, human-readable version of the permission, such as "View", "Edit", etc.
 *
 * @example
 * ```typescript
 * const shortName = permissionParser(FacilityPermissions.ViewFacility); // returns "View"
 * const shortName = permissionParser(CorrectiveWorkorderPermissions.EditCorrectiveWorkorder); // returns "Edit"
 * ```
 */
export const permissionParser = (permission: AllPermissions | undefined): string => {
    if (!permission) return ''
    const modelTypes = [
        'document',
        'equipment',
        'remark',
        'correctivemaintenance',
        'preventivemaintenance',
        'preventive_workorder',
        'corrective_workorder',
    ]

    let normalized = permission.toLowerCase()

    // Remove trailing model type (and the leading underscore) if present
    for (const model of modelTypes) {
        const suffix = `_${model}`
        if (normalized.endsWith(suffix)) {
            normalized = normalized.slice(0, -suffix.length)
            break
        }
    }

    return capitalizeFirstLetter(normalized.replace(/_/g, '-'))
}

/**
 * Adds or updates the 'model' query parameter in a URL.
 *
 * @param {string | URL} originalUrl - The original URL (absolute or relative).
 * @param {'document' | 'equipment' | 'remark' | 'corrective' | 'preventive'} modelName
 *                                - The model name to set in the 'model' query parameter.
 * @returns {string} - The updated URL with the 'model' parameter.
 *
 * @example
 * addModelNameToPaginationUrl('/items?page=2', 'document')
 * // Returns: '/items?page=2&model=document'
 */
export const addModelNameToPaginationUrl = (originalUrl: string | URL, modelName: ModelType): string | URL => {
    try {
        const isAbsolute = /^https?:\/\//i.test(originalUrl.toString())
        const base = isAbsolute ? undefined : window.location.origin

        const url = new URL(originalUrl, base)

        url.searchParams.set('model', modelName)

        if (!isAbsolute) {
            return url.pathname + url.search
        }

        return url.toString()
    } catch (error) {
        console.error('Invalid URL provided:', originalUrl)
        console.error(error)
        const separator = originalUrl.toString().includes('?') ? '&' : '?'
        return `${originalUrl}${separator}model=${modelName}`
    }
}

export const getNiceGroupNameFromGroup = (group: IGroup, t: any): string => {
    const handleCompanyAdminGroup = (group: IGroup): string | null => {
        if (group.company) {
            const parts = group.name.split('_')
            const nameHasThreeParts = parts.length === 3
            const twoLastPartsAreCompanyAndAdmin = parts[1] === 'company' && parts[2] === 'admin'
            if (nameHasThreeParts && twoLastPartsAreCompanyAndAdmin) {
                return `${group.company.name} ${t('group-and-permission.company-admin')}`
            }
        }
        return null
    }

    const handleCompanyReadAllGroup = (group: IGroup): string | null => {
        if (group.company) {
            const parts = group.name.split('_')
            const nameHasThreeParts = parts.length === 3
            const twoLastPartsAreReadAndAll = parts[1] === 'read' && parts[2] === 'all'
            if (nameHasThreeParts && twoLastPartsAreReadAndAll) {
                return `${group.company.name} ${t('group-and-permission.company-read-all').toLowerCase()}`
            }
        }
        return null
    }

    const handleFacilityGroups = (group: IGroup): string | null => {
        if (group.facility) {
            const parts = group.name.split('_')
            const nameHasThreeParts = parts.length === 3
            const twoLastPartsAreFacilityAndAdmin = parts[1] === 'facility' && parts[2] === 'admin'
            const twoLastPartsAreFacilityAndEmployee = parts[1] === 'facility' && parts[2] === 'employee'

            if (nameHasThreeParts && twoLastPartsAreFacilityAndAdmin) {
                return `${group.facility.name} ${t('group-and-permission.facility-admin').toLowerCase()}`
            }
            if (nameHasThreeParts && twoLastPartsAreFacilityAndEmployee) {
                return `${group.facility.name} ${t('group-and-permission.facility-employee').toLowerCase()}`
            }
        }
        return null
    }

    const handleSpecialGroup = (group: IGroup): string | null => {
        const parts = group.name.split('_')
        if (parts.length < 4 || parts[parts.length - 1] !== 'all') {
            return null
        }

        const facilityPart = parts[0]
        const modelKey = parts[1]
        const actionKey = parts.slice(2, parts.length - 1).join('_')

        const facilityName = group.facility?.name || group.company?.name || facilityPart

        const actionTranslation = t(`group-and-permission.labels.${actionKey}`)
        const modelTranslation = t(`group-and-permission.models.${modelKey}`)
        return t('group-and-permission.dynamic.nice', {
            action: actionTranslation,
            model: modelTranslation,
            facility: facilityName,
        })
    }

    const companyAdminName = handleCompanyAdminGroup(group)
    if (companyAdminName) return companyAdminName

    const companyReadAllName = handleCompanyReadAllGroup(group)
    if (companyReadAllName) return companyReadAllName

    const facilityGroupName = handleFacilityGroups(group)
    if (facilityGroupName) return facilityGroupName

    const specialGroupName = handleSpecialGroup(group)
    if (specialGroupName) return specialGroupName

    return group.display_name || group.name
}

export const parseBoolFromLocalStorage = (value: string | null): boolean => {
    if (!value) return false // Handle null/undefined from localStorage.getItem
    const normalized = value.trim().toLowerCase()

    const truthyValues = new Set(['true', '1', 'yes', 'on'])
    const falsyValues = new Set(['false', '0', 'no', 'off'])

    if (truthyValues.has(normalized)) {
        return true
    }
    if (falsyValues.has(normalized)) {
        return false
    }

    // Optionally, decide what to do for non-explicit values.
    // By default, we return false. (Some might prefer throwing an error instead.)
    return false
}
